export interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: () => void;
  component: any;
}

const Icon: React.FC<IconProps> = ({ onClick, component: Component, ...props }) => {
  const defaultProps = {
    ...props,
    onClick,
    width: '1em',
    height: '1em',
  };
  if (Component) {
    return 'render' in Component ? Component.render({ ...defaultProps }) : Component(defaultProps);
  }
  return null;
};

export default Icon;
